















import { Component, Vue } from 'vue-property-decorator';
import VueRouter, { Route, NavigationGuardNext } from 'vue-router';
import Error from '../../../components/contents/Error.vue';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
]);

@Component({
  components: {
    Error,
  },
})
export default class AccountEditError extends Vue {
  private beforePath: string = '/account/edit_account/confirm/';

  private get status(): number {
    const $parent: any = this.$parent;
    return $parent.status;
  }

  private get errorCode(): number {
    const $parent: any = this.$parent;
    return $parent.errorCode;
  }

  private async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    const $parent: any = this.$parent;
    $parent.resetStatusCode();
    next();
  }
}
